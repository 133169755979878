import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {AdjuntoRaizQueryResponse, CavGuiaQueryResponse, ClasseQueryResponse, ClassificacoQueryResponse, CoursesQueryResponse, EnrollCourse, EscalaDevas, EscalaDevasQueryResponse, FalangeMestradoQueryResponse, FalangeMissionariaQueryResponse, FileResp, MadrinhaQueryResponse, Medium, MediumAudit, MediumQueryResponse, MediumsAuditQueryResponse, MediumsQueryResponse, MinistroQueryResponse, NinfaLuaQueryResponse, NinfaSolQueryResponse, PadrinhoQueryResponse, PovoQueryResponse, SubClasseQueryResponse, TemplateEmissao, TemplesQueryResponse, Templo, TurnoCavGuiaQueryResponse, TurnoTrabalhoQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const MEDIUM_URL = `${API_URL}/medium`
const MEDIUNS_URL = `${API_URL}/mediuns`
const ESCALA_URL = `${API_URL}/timeshift`
const MEDIUM_BY_TEMPLO_URL = `${API_URL}/mediums/query/temple`
const DEVAS_BY_TEMPLO_URL = `${API_URL}/mediums/devas/query/temple`

const GET_MEDIUMS_URL = `${API_URL}/mediums/query`
const GET_CURSOS_URL = `${API_URL}/courses`
const ENROLL_URL = `${API_URL}/course/enroll`

const UPLOAD_IMG_URL = `${API_URL}/image-upload`

const TEMPLE_URL = `${API_URL}/temple`
const GET_FULL_TEMPLES_URL = `${API_URL}/temples`
const GET_TEMPLES_URL = `${API_URL}/temples/query`


const TEMPLATE_URL = `${API_URL}/template`
const TEMPLATE_URL_TEMPLOS = `${API_URL}/template/templos`

const GET_FALANGE_MISSIONARIA_URL = `${API_URL}/falanges-missionarias`
const GET_FALANGE_MESTRADO_URL = `${API_URL}/falanges-mestrado`
const GET_CLASSE_URL = `${API_URL}/classes`
const GET_SUB_CLASSE_URL = `${API_URL}/sub-classes`
const GET_POVO_URL = `${API_URL}/povos`
const GET_ADJUNTO_RAIZ_URL = `${API_URL}/adjuntos-raiz`
const GET_TURNO_TRABALHO_URL = `${API_URL}/turnos-trabalho`
const GET_MINISTRO_URL = `${API_URL}/ministros`
const GET_CAVALEIROS_GUIAS_MISSIONARIAS_URL = `${API_URL}/cavaleiros-guias`
const GET_CLASSIFICACOES_URL = `${API_URL}/classificacoes-mestrado`
const GET_PADRINHOS_URL = `${API_URL}/padrinhos-mestre`
const GET_MADRINHAS_URL = `${API_URL}/madrinhas-mestre`
const GET_NINFAS_LUA_URL = `${API_URL}/ninfas-lua-escrava`
const GET_NINFAS_SOL_URL = `${API_URL}/ninfas-sol-escrava`

const getMediums = (query: string, id_templo: string, is_admin: number): Promise<MediumsQueryResponse> => {
  return axios
    .get(`${GET_MEDIUMS_URL}?${query}&templo=${id_templo}&is_admin=${is_admin}`)
    .then((response: AxiosResponse<MediumsQueryResponse>) => response.data )
}

const getCursos = (id_templo: number): Promise<CoursesQueryResponse> => {
  return axios
    .get(`${GET_CURSOS_URL}`)
    .then((response: AxiosResponse<CoursesQueryResponse>) => response.data)
}

const getEnrollByMedium = (id: ID): Promise<EnrollCourse | undefined> => {
  return axios
    .get(`${ENROLL_URL}/${id}`)
    .then((response: AxiosResponse<Response<EnrollCourse>>) => response.data)
    .then((response: Response<EnrollCourse>) => response.data)
}

const getMediumById = (id: ID): Promise<Medium | undefined> => {
  return axios
    .get(`${MEDIUM_URL}/${id}`)
    .then((response: AxiosResponse<Response<Medium>>) => response.data)
    .then((response: Response<Medium>) => response.data)
}

const getSimpleMediumById = (id: ID): Promise<Medium | undefined> => {
  return axios
    .get(`${MEDIUM_URL}/${id}/simple`)
    .then((response: AxiosResponse<Response<Medium>>) => response.data)
    .then((response: Response<Medium>) => response.data)
}

const getSimpleMediumByEmail = (email: string): Promise<Medium | undefined> => {
  return axios
    .get(`${MEDIUM_URL}/${email}/email/simple`)
    .then((response: AxiosResponse<Response<Medium>>) => response.data)
    .then((response: Response<Medium>) => response.data)
}

const createMedium = (medium: Medium): Promise<Medium | undefined> => {
  return axios
    .post(MEDIUM_URL, medium)
    .then((response: AxiosResponse<Response<Medium>>) => response.data)
    .then((response: Response<Medium>) => response.data)
}

const enrollCourse = (enroll: EnrollCourse): Promise<EnrollCourse | undefined> => {
  return axios
    .post(ENROLL_URL, enroll)
    .then((response: AxiosResponse<Response<EnrollCourse>>) => response.data)
    .then((response: Response<EnrollCourse>) => response.data)
}

const updateMedium = (medium: Medium): Promise<Medium | undefined> => {
  return axios
    .post(`${MEDIUM_URL}/${medium.id_medium}`, medium)
    .then((response: AxiosResponse<Response<Medium>>) => response.data)
    .then((response: Response<Medium>) => response.data)
}

const getAllAudits = (): Promise<MediumsAuditQueryResponse> => {
  return axios
    .get(`${MEDIUNS_URL}/audit`)
    .then((response: AxiosResponse<MediumsAuditQueryResponse>) => response.data )
}

const getAllAuditsByIdMedium = (id_medium: number): Promise<MediumsAuditQueryResponse> => {
  return axios
    .get(`${MEDIUM_URL}/audit/${id_medium}`)
    .then((response: AxiosResponse<MediumsAuditQueryResponse>) => response.data )
}

const getAudit = (id_medium: number): Promise<MediumAudit | undefined> => {
  return axios
    .get(`${MEDIUM_URL}/${id_medium}/audit`)
    .then((response: AxiosResponse<Response<MediumAudit>>) => response.data)
    .then((response: Response<MediumAudit>) => response.data)
}

const createMediumAudit = (medium: Medium): Promise<Medium | undefined> => {
  var requester = localStorage.getItem('key_id_medium_login');
  return axios
    .post(`${MEDIUM_URL}/${medium.id_medium}/audit/${requester}`, medium)
    .then((response: AxiosResponse<Response<Medium>>) => response.data)
    .then((response: Response<Medium>) => response.data)
}

const updateMediumAudit = (medium_audit: MediumAudit): Promise<MediumAudit | undefined> => {
  var approver = localStorage.getItem('key_id_medium_login');
  return axios
    .post(`${MEDIUM_URL}/${medium_audit.id_medium_audit}/audit/approve/${approver}`, medium_audit)
    .then((response: AxiosResponse<Response<MediumAudit>>) => response.data)
    .then((response: Response<MediumAudit>) => response.data)
}

const updateFoto = (id_medium: number, formData: FormData): Promise<FileResp | undefined> => {
  return axios
    .post(`${UPLOAD_IMG_URL}/${id_medium}`, formData)
    .then((response: AxiosResponse<Response<FileResp>>) => response.data)
    .then((response: Response<FileResp>) => response.data)
}

const updateMediumChild = (medium: Medium): Promise<Medium | undefined> => {
  return axios
    .post(`${MEDIUM_URL}/${medium.id_medium}/vinculados`, medium)
    .then((response: AxiosResponse<Response<Medium>>) => response.data)
    .then((response: Response<Medium>) => response.data)
}

const deleteMedium = (mediumId: ID): Promise<void> => {
  return axios.delete(`${MEDIUM_URL}/${mediumId}`).then(() => {})
}

const getEscalaByTemplo = (id_templo: number): Promise<EscalaDevasQueryResponse> => {
  return axios
    .get(`${ESCALA_URL}/${id_templo}`)
    .then((response: AxiosResponse<EscalaDevasQueryResponse>) => response.data )
}

const updateEscala = (escala: EscalaDevas[]): Promise<EscalaDevas | undefined> => {
  return axios
    .post(ESCALA_URL, escala)
    .then((response: AxiosResponse<Response<EscalaDevas>>) => response.data)
    .then((response: Response<EscalaDevas>) => response.data)
}

const removerEscala = (id_escala: number): Promise<EscalaDevas | undefined> => {
  return axios
    .delete(`${ESCALA_URL}/${id_escala}`)
    .then((response: AxiosResponse<Response<EscalaDevas>>) => response.data)
    .then((response: Response<EscalaDevas>) => response.data)
}

const createTemple = (temple: Templo): Promise<Templo | undefined> => {
  return axios
    .post(TEMPLE_URL, temple)
    .then((response: AxiosResponse<Response<Templo>>) => response.data)
    .then((response: Response<Templo>) => response.data)
}

const updateTemple = (temple: Templo): Promise<Templo | undefined> => {
  return axios
    .post(`${TEMPLE_URL}/${temple.id_templo}`, temple)
    .then((response: AxiosResponse<Response<Templo>>) => response.data)
    .then((response: Response<Templo>) => response.data)
}

const deleteSelectedMediums = (mediumIds: Array<ID>): Promise<void> => {
  const requests = mediumIds.map((id) => axios.delete(`${MEDIUM_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getTemples = (query: string): Promise<TemplesQueryResponse> => {
  return axios
    .get(`${GET_TEMPLES_URL}?${query}`)
    .then((response: AxiosResponse<TemplesQueryResponse>) => response.data)
}

const getTemplesFull = (): Promise<TemplesQueryResponse> => {
  return axios
    .get(`${GET_FULL_TEMPLES_URL}`)
    .then((response: AxiosResponse<TemplesQueryResponse>) => response.data)
}

const getTempleById = (id: ID): Promise<Templo | undefined> => {
  return axios
    .get(`${TEMPLE_URL}/${id}`)
    .then((response: AxiosResponse<Response<Templo>>) => response.data)
    .then((response: Response<Templo>) => response.data)
}

// template emissao TEMPLO MAE = 1
const getTemplateById = (id: ID, id_templo: ID, medium: Medium): Promise<TemplateEmissao | undefined> => {
  //const raizes_fora_templo_mae = [4 , 6, 15, 19, 20, 21, 24];
  //const is_raiz_amanhecer = raizes_fora_templo_mae.includes(medium.adjunto_raiz?.id_adjunto_raiz || 0) ;
  const is_templo_raiz_amanhecer = medium.adjunto_raiz?.id_medium_representante === medium.templo?.id_medium_presidente;
  const is_principe = medium.falangeMissionaria?.id_falange_missionaria === 6;
  const is_apara = medium.mediunidadeDTO?.id_mediunidade === 1;
  const is_principe_lua = (is_principe && is_apara);
  const tag_principe = is_principe_lua ? 'principes-lua' : '';
  const tag_base = `${( (id_templo === 1 || is_templo_raiz_amanhecer) ? TEMPLATE_URL : TEMPLATE_URL_TEMPLOS)}/${id}/${tag_principe}`;
  return axios
    .get(tag_base)
    .then((response: AxiosResponse<Response<TemplateEmissao>>) => response.data)
    .then((response: Response<TemplateEmissao>) => response.data)
}

// Elevacao
const getFalangesMissionarias = (): Promise<FalangeMissionariaQueryResponse | undefined> => {
  return axios
    .get(`${GET_FALANGE_MISSIONARIA_URL}`)
    .then((response: AxiosResponse<FalangeMissionariaQueryResponse>) => response.data)
}

const getFalangesMestrado = (): Promise<FalangeMestradoQueryResponse> => {
  return axios
    .get(`${GET_FALANGE_MESTRADO_URL}`)
    .then((response: AxiosResponse<FalangeMestradoQueryResponse>) => response.data)
}


// Centuria

const getClasses = (): Promise<ClasseQueryResponse> => {
  return axios
    .get(`${GET_CLASSE_URL}`)
    .then((response: AxiosResponse<ClasseQueryResponse>) => response.data)
}

const getSubClasses = (): Promise<SubClasseQueryResponse> => {
  return axios
    .get(`${GET_SUB_CLASSE_URL}`)
    .then((response: AxiosResponse<SubClasseQueryResponse>) => response.data)
}

const getPovos = (): Promise<PovoQueryResponse | undefined> => {
  return axios
    .get(`${GET_POVO_URL}`)
    .then((response: AxiosResponse<PovoQueryResponse>) => response.data)
}

const getAdjuntosRaiz = (): Promise<AdjuntoRaizQueryResponse | undefined> => {
  return axios
    .get(`${GET_ADJUNTO_RAIZ_URL}`)
    .then((response: AxiosResponse<AdjuntoRaizQueryResponse>) => response.data)
}

const getTurnosTrabalho = (): Promise<TurnoTrabalhoQueryResponse | undefined> => {
  return axios
    .get(`${GET_TURNO_TRABALHO_URL}`)
    .then((response: AxiosResponse<TurnoTrabalhoQueryResponse>) => response.data)
}

const getMinistros = (): Promise<MinistroQueryResponse | undefined> => {
  return axios
    .get(`${GET_MINISTRO_URL}`)
    .then((response: AxiosResponse<MinistroQueryResponse>) => response.data)
}

const getCavaleirosGuias = (sexo: string): Promise<CavGuiaQueryResponse | undefined> => {
  return axios
    .get(`${GET_CAVALEIROS_GUIAS_MISSIONARIAS_URL}/${sexo}`)
    .then((response: AxiosResponse<CavGuiaQueryResponse>) => response.data)
}

//CLASSIFICACAO

const getClassificacoes = (id_mediunidade: ID,): Promise<ClassificacoQueryResponse> => {
  return axios
    .get(`${GET_CLASSIFICACOES_URL}/${id_mediunidade}`)
    .then((response: AxiosResponse<ClassificacoQueryResponse>) => response.data)
}

const getAllMediumsByTemplo = (id_templo: ID): Promise<MediumQueryResponse> => {
  var query = `page=0&rowsperpage=10000&templo=${id_templo}`
  return axios
    .get(`${MEDIUM_BY_TEMPLO_URL}?${query}`)
    .then((response: AxiosResponse<MediumQueryResponse>) => response.data)
}

const getAllDevasByTemplo = (id_templo: ID): Promise<MediumQueryResponse> => {
  var query = `page=0&rowsperpage=10000&templo=${id_templo}`
  return axios
    .get(`${DEVAS_BY_TEMPLO_URL}?${query}`)
    .then((response: AxiosResponse<MediumQueryResponse>) => response.data)
}

const getPadrinhos = (id_templo: ID, id_adjunto: ID,): Promise<PadrinhoQueryResponse> => {
  return axios
    .get(`${GET_PADRINHOS_URL}/${id_templo}/${id_adjunto}`)
    .then((response: AxiosResponse<PadrinhoQueryResponse>) => response.data)
}

const getMadrinhas = (id_templo:ID, id_adjunto: ID,): Promise<MadrinhaQueryResponse> => {
  return axios
    .get(`${GET_MADRINHAS_URL}/${id_templo}/${id_adjunto}`)
    .then((response: AxiosResponse<MadrinhaQueryResponse>) => response.data)
}

const getNinfasLua = (id_templo:ID, id_adjunto: ID,): Promise<NinfaLuaQueryResponse> => {
  return axios
    .get(`${GET_NINFAS_LUA_URL}/${id_templo}/${id_adjunto}`)
    .then((response: AxiosResponse<NinfaLuaQueryResponse>) => response.data)
}

const getNinfasSol = (id_templo:ID, id_adjunto: ID,): Promise<NinfaSolQueryResponse> => {
  return axios
    .get(`${GET_NINFAS_SOL_URL}/${id_templo}/${id_adjunto}`)
    .then((response: AxiosResponse<NinfaSolQueryResponse>) => response.data)
}

export {
  createMedium, updateMedium, createMediumAudit, updateMediumAudit, updateFoto, deleteMedium, deleteSelectedMediums, updateMediumChild, 
  getMediums, getAudit, getAllMediumsByTemplo, getMediumById, getSimpleMediumById, getSimpleMediumByEmail,
  getAllDevasByTemplo, getAllAudits, getAllAuditsByIdMedium,
  createTemple, updateTemple, getTempleById, getTemples, getTemplesFull, getTemplateById, 
  getFalangesMissionarias, getFalangesMestrado, getClasses, getSubClasses,
  getPovos, getAdjuntosRaiz, getTurnosTrabalho, 
  getClassificacoes, getPadrinhos, getMadrinhas, getNinfasLua, getNinfasSol, getMinistros, getCavaleirosGuias,
  getCursos, getEnrollByMedium, enrollCourse,
  getEscalaByTemplo, updateEscala, removerEscala
}
