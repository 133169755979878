/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { AuthorizedFunction } from '../../../../app/modules/auth/components/AuthorizedFunction'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/app/dashboard'
        title='Home'
        fontIcon='bi-house fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-2'
      />
      {/* <AsideMenuItemWithSubMain
        to='/cadastro/pages'
        title='Médiuns'
        fontIcon='bi-file-text'
        bsTitle='Médiuns'
      >
        <AsideMenuItemWithSub to='/cadastro/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem
            to='/cadastro/pages/profile/overview'
            title='Overview'
            bsTitle='Overview'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/cadastro/pages/profile/projects'
            title='Projects'
            bsTitle='Projects'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/cadastro/pages/profile/campaigns'
            title='Campaigns'
            bsTitle='Campaigns'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/cadastro/pages/profile/documents'
            title='Documents'
            bsTitle='Documents'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/cadastro/pages/profile/connections'
            title='Connections'
            hasBullet={true}
            bsTitle='Connections'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/cadastro/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/cadastro/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
            bsTitle='Horizontal'
          />
          <AsideMenuItem
            to='/cadastro/pages/wizards/vertical'
            title='Vertical'
            bsTitle='Vertical'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/enroll/medium' title='Accounts' hasBullet={true}>
          <AsideMenuItem
            to='/app/enroll/medium/:number/detalhe'
            title='Overview'
            hasBullet={true}
            bsTitle='Overview'
          />
          <AsideMenuItem
            to='/app/enroll/medium/alteracao'
            title='Settings'
            hasBullet={true}
            bsTitle='Settings'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/cadastro/widgets' title='Widgets' hasBullet={true}>
          <AsideMenuItem
            to='/cadastro/widgets/lists'
            title='Lists'
            bsTitle='Lists'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/cadastro/widgets/statistics'
            title='Statistics'
            bsTitle='Statistics'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/cadastro/widgets/charts'
            title='Charts'
            bsTitle='Charts'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/cadastro/widgets/mixed'
            title='Mixed'
            bsTitle='Mixed'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/cadastro/widgets/tables'
            title='Tables'
            bsTitle='Tables'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/cadastro/widgets/feeds'
            title='Feeds'
            bsTitle='Feeds'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/apps/chat' title='Chat' hasBullet={true}>
          <AsideMenuItem
            to='/apps/chat/private-chat'
            title='Private Chat'
            bsTitle='Private Chat'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/group-chat'
            title='Group Chart'
            bsTitle='Group Chart'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/drawer-chat'
            title='Drawer Chart'
            bsTitle='Drawer Chart'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='/error' title='Errors' hasBullet={true}>
          <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </AsideMenuItemWithSub>
        </AsideMenuItemWithSubMain>
  */}

      {/* {AuthorizedFunction(['operacional-devas']) && 
        <AsideMenuItem
          to='/app/management/legiao/cavguia'
          title='Legião'
          bsTitle='Legião'
          fontIcon='bi-sun'
          className='py-2'
        />  
      }

      {AuthorizedFunction(['operacional-devas']) && 
        <AsideMenuItem
          to='/app/management/adjunto/raiz'
          title='Adjuntos'
          bsTitle='Adjuntos'
          fontIcon='bi-diagram-3'
          className='py-2'
        />
      }

      {AuthorizedFunction(['operacional-devas']) && 
        <AsideMenuItem
          to='/app/management/falange/missionarias'
          title='Falanges'
          bsTitle='Falanges'
          fontIcon='bi-moon-stars'
          className='py-2'
        />
      }
      
      */}

      {AuthorizedFunction(['operacional-devas']) && 
        <AsideMenuItem
          to='/app/management/medium/search'
          title='Médiuns'
          bsTitle='Médiuns'
          fontIcon='bi-person-badge'
          className='py-2'
        />
      }

      {AuthorizedFunction(['admin-sistema']) && 
        <AsideMenuItem
          to='/app/management/templo/search'
          title='Templos'
          bsTitle='Templos'
          fontIcon='bi-houses'
          className='py-2'
        />
      }
{/* 
      {AuthorizedFunction(['admin-sistema']) && 
        <AsideMenuItem
          to='/app/management/devas/escala'
          title='Devas'
          bsTitle='Devas'
          fontIcon='bi-flag'
          className='py-2'
        />
    
      }
*/}
      
    {/*
    
      <AsideMenuItem
        to='/emissao'
        title='Emissão'
        bsTitle='Emissão dos Médiuns'
        fontIcon='bi-chat-left-dots'
        className='py-2'
      />

      <AsideMenuItemWithSubMain
        to='/admin'
        title='Admin'
        bsTitle='Admin'
        fontIcon='bi-gear'
      >
        <AsideMenuItem to='/admin' title='Templates de Emissão' fontIcon='bi-layers fs-3' />
      
      </AsideMenuItemWithSubMain>
    */}

    </>
  )
}
